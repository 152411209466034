// store/goods-master/sagas.js

import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  GET_GOODS_MASTER,
  REGISTER_GOODS_MASTER,
} from "./actionTypes";
import {
  getGoodsMasterSuccess,
  getGoodsMasterFail,
  registerGoodsMasterSuccess,
  registerGoodsMasterFail,
} from "./actions";

import { getGoodsMaster, registerGoodsMaster } from "../../helpers/backend_helper";

// 既存のサガ: 商品マスタの取得
function* fetchGoodsMaster({ payload: { categoryId, locationId } }) {
  try {
    const response = yield call(getGoodsMaster, categoryId, locationId);
    yield put(getGoodsMasterSuccess(response.data));
  } catch (error) {
    yield put(getGoodsMasterFail(error.message));
  }
}

// 新規追加: 商品マスタの登録
function* handleRegisterGoodsMaster({ payload: item }) {
  try {
    yield call(registerGoodsMaster, item);
    yield put(registerGoodsMasterSuccess());
    // 登録成功後、最新のデータを再取得
    const response = yield call(getGoodsMaster, item.category_id, item.location_id);
    yield put(getGoodsMasterSuccess(response.data));
  } catch (error) {
    yield put(registerGoodsMasterFail(error));
  }
}

// ルートサガ: すべてのサガをまとめて実行
export default function* goodsMasterSaga() {
  yield all([
    takeLatest(GET_GOODS_MASTER, fetchGoodsMaster),
    takeLatest(REGISTER_GOODS_MASTER, handleRegisterGoodsMaster),
  ]);
}
