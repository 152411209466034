// src/pages/DeliveryPlan/DeliveryPlanCreate.js

import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Input,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { useDispatch, useSelector } from "react-redux"
import { fetchUsersData } from "../../store/users/actions"
import { fetchWarehouses } from "../../store/warehouse/actions"
import { fetchSoldOut } from "../../store/soldout/actions"
import "./App.css"

import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

// Google Maps 関連のコンポーネント
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow
} from "@react-google-maps/api"
import { googleMapsConfig } from "./googleMapsConfig"

const DeliveryPlanCreate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const isLoading1 = useSelector(state => state.usersReducer.loading)
  const isLoading2 = useSelector(state => state.warehouseReducer.loading)
  const isLoading3 = useSelector(state => state.soldOutReducer.loading)

  const {
    isAddingLocations,
    originalPlanId,
    selectedUser: initialSelectedUser,
    startWarehouseId,
    startWarehouseAddress,
    endWarehouseId,
    endWarehouseAddress,
    selectedLocations: initialSelectedLocations,
    startDate: initialStartDate,
    endDate: initialEndDate,
  } = location.state || {}

  const isEditMode = location.state ? location.state.isAddingLocations : false

  const getDefaultDates = () => {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(now.getDate() + 1)
    tomorrow.setHours(13, 0, 0, 0)
    const offset = tomorrow.getTimezoneOffset() * 60000
    const defaultStartDate = new Date(tomorrow.getTime() - offset)
      .toISOString()
      .slice(0, 16)
    const fiveHoursLater = new Date(tomorrow)
    fiveHoursLater.setHours(tomorrow.getHours() + 5)
    const defaultEndDate = new Date(fiveHoursLater.getTime() - offset)
      .toISOString()
      .slice(0, 16)
    return {
      defaultStartDate,
      defaultEndDate,
    }
  }

  const { defaultStartDate, defaultEndDate } = getDefaultDates()

  const [startDate, setStartDate] = useState(initialStartDate || defaultStartDate)
  const [endDate, setEndDate] = useState(initialEndDate || defaultEndDate)
  const [selectedStartWarehouse, setSelectedStartWarehouse] = useState(null)
  const [selectedEndWarehouse, setSelectedEndWarehouse] = useState(null)
  const [selectedUser, setSelectedUser] = useState(initialSelectedUser || null)
  const [displayLocations, setDisplayLocations] = useState(false)
  const [selectedLocations, setSelectedLocations] = useState(
    initialSelectedLocations || []
  )
  const [visibleStocks, setVisibleStocks] = useState({})
  const [showOnlySoldOut, setShowOnlySoldOut] = useState(
    location.state?.showOnlySoldOut !== undefined ? location.state.showOnlySoldOut : true
  )
  const [completedLocations, setCompletedLocations] = useState([])
  const [mapMarkers, setMapMarkers] = useState([])
  const [warehouseMarker, setWarehouseMarker] = useState(null)

  // 追加: 商品マスタ未登録商品の有無チェック用
  const [hasUnregisteredGoods, setHasUnregisteredGoods] = useState(false)

  // 追加: モーダルの表示制御
  const [isOpen, setIsOpen] = useState(false)
  const [selectedNullGoodsLocation, setSelectedNullGoodsLocation] = useState(null)

  // ★ 追加: クラスタ内の複数マーカー情報（InfoWindow 表示用）
  const [clusterInfo, setClusterInfo] = useState(null)

  const userData = useSelector(state => state.usersReducer.userData)
  const users = userData && userData.data ? userData.data.results : []

  const warehouses = useSelector(state => state.warehouseReducer.warehouses) || []

  console.log("Warehouses:", warehouses)

  const soldOutData = useSelector(state => state.soldOutReducer.soldOutData) || []
  const route = useSelector(state => state.soldOutReducer.route) || ""

  const { isLoaded, loadError } = useJsApiLoader(googleMapsConfig)
  const mapRef = useRef(null)

  const warehouseOptions = warehouses.map(warehouse => ({
    value: warehouse,
    label: warehouse.warehouse_name,
  }))

  const userOptions = users.map(user => ({
    value: user.user_id,
    label: user.user_name,
  }))

  useEffect(() => {
    dispatch(fetchUsersData())
    dispatch(fetchWarehouses())
  }, [dispatch])

  useEffect(() => {
    const shouldFetchData =
      !displayLocations &&
      warehouses.length > 0 &&
      initialSelectedUser &&
      startWarehouseId

    console.log("shouldFetchData:", shouldFetchData)

    if (shouldFetchData) {
      const startWarehouseIdNum =
        typeof startWarehouseId === "string"
          ? Number(startWarehouseId)
          : startWarehouseId
      const endWarehouseIdNum =
        typeof endWarehouseId === "string"
          ? Number(endWarehouseId)
          : endWarehouseId

      const startWarehouse = warehouses.find(
        w => w.warehouse_id === startWarehouseIdNum
      )
      const endWarehouse = warehouses.find(
        w => w.warehouse_id === endWarehouseIdNum
      )

      const startWarehouseOption = {
        value: startWarehouse,
        label: startWarehouse?.warehouse_name || "Unknown",
      }

      const endWarehouseOption = {
        value: endWarehouse,
        label: endWarehouse?.warehouse_name || "Unknown",
      }

      setSelectedUser(initialSelectedUser)
      setSelectedStartWarehouse(startWarehouseOption)
      setSelectedEndWarehouse(endWarehouseOption)

      if (startWarehouseAddress && isLoaded && !loadError) {
        geocodeWarehouse(startWarehouseAddress)
      }
      if (endWarehouseAddress && isLoaded && !loadError) {
        geocodeWarehouse(endWarehouseAddress)
      }

      dispatch(
        fetchSoldOut({
          warehouseId: startWarehouseIdNum,
          userId: initialSelectedUser.value,
          onlySoldOut: showOnlySoldOut,
        })
      )
      setDisplayLocations(true)
    }
  }, [
    warehouses,
    initialSelectedUser,
    startWarehouseId,
    startWarehouseAddress,
    endWarehouseId,
    endWarehouseAddress,
    displayLocations,
    isLoaded,
    loadError,
    dispatch,
    showOnlySoldOut,
  ])

  const soldOutLocIds = new Set(soldOutData.map(loc => loc.loc_id))
  const stateLocations = location.state?.locations
    ? location.state.locations.slice(1, -1)
    : []
  const extraLocations = stateLocations.filter(loc => !soldOutLocIds.has(loc.id))

  const allLocations = [
    ...soldOutData.map(loc => ({ ...loc, fromSoldOut: true })),
    ...extraLocations.map(loc => ({
      loc_id: loc.id,
      loc_name: loc.name || "ロケーション",
      loc_address: loc.address || "住所不明",
      fromSoldOut: false,
      stocks: []
    }))
  ]

  useEffect(() => {
    if (soldOutData.length > 0) {
      const initialVisibleStocks = {}
      soldOutData.forEach(location => {
        initialVisibleStocks[location.loc_id] = true
      })
      setVisibleStocks(initialVisibleStocks)
      geocodeAddresses()
    } else {
      if (soldOutData.length === 0) {
        setMapMarkers(prev => (prev.length === 0 ? prev : []))
        setVisibleStocks(prev => (Object.keys(prev).length === 0 ? prev : {}))
      }
    }
  }, [soldOutData])

  // フラグ管理用
  const [allLocationsReady, setAllLocationsReady] = useState(false)
  const [hasGeocodedRef, setHasGeocodedRef] = useState(false)

  useEffect(() => {
    if (
      displayLocations &&
      (soldOutData.length > 0 || extraLocations.length > 0) &&
      allLocations.length > 2
    ) {
      setAllLocationsReady(true)
    }
  }, [displayLocations, soldOutData, extraLocations, allLocations])

  useEffect(() => {
    if (isLoaded && !loadError && displayLocations && allLocationsReady && !hasGeocodedRef) {
      geocodeAddresses()
      setHasGeocodedRef(true)
    }
  }, [isLoaded, loadError, displayLocations, allLocationsReady, hasGeocodedRef])

  useEffect(() => {
    if (selectedStartWarehouse && selectedStartWarehouse.value.address) {
      geocodeWarehouse(selectedStartWarehouse.value.address)
    } else {
      setWarehouseMarker(null)
    }
  }, [selectedStartWarehouse, isLoaded, loadError])

  // soldOutData 内の商品マスタ未登録商品の存在チェック
  useEffect(() => {
    if (!soldOutData || soldOutData.length === 0) {
      setHasUnregisteredGoods(false)
      return
    }

    let foundNullGoodsId = false
    soldOutData.forEach(location => {
      location.stocks.forEach(stock => {
        if (stock.goods_id === null) {
          foundNullGoodsId = true
        }
      })
    })
    setHasUnregisteredGoods(foundNullGoodsId)
  }, [soldOutData])

  const geocodeAddresses = async () => {
    if (!isLoaded || loadError) {
      console.error("Google Maps APIがロードされていません。")
      return
    }

    const geocoder = new window.google.maps.Geocoder()
    const markerPromises = allLocations.map((location, index) => {
      return new Promise((resolve) => {
        if (location.loc_address && location.loc_address !== "住所指定なし") {
          geocoder.geocode({ address: location.loc_address }, (results, status) => {
            if (status === "OK" && results[0]) {
              resolve({
                position: results[0].geometry.location.toJSON(),
                loc_name: location.loc_name,
                loc_id: location.loc_id,
                fromSoldOut: location.fromSoldOut,
                allLocationsIndex: index,
              })
            } else {
              console.error(`ジオコーディング失敗 (${location.loc_address}):`, status)
              resolve(null)
            }
          })
        } else {
          resolve(null)
        }
      })
    })

    const markers = await Promise.all(markerPromises)
    setMapMarkers(markers)
  }

  const geocodeWarehouse = async (address) => {
    if (!isLoaded || loadError || !address) {
      console.error("Google Maps APIがロードされていないか、住所が指定されていません。")
      return
    }

    if (!window.google || !window.google.maps) {
      console.error("Google Maps APIが正しくロードされていません。")
      return
    }

    const geocoder = new window.google.maps.Geocoder()
    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === "OK" && results[0]) {
            resolve(results[0])
          } else {
            reject(new Error(`Geocoding failed: ${status}`))
          }
        })
      })

      setWarehouseMarker({
        position: result.geometry.location.toJSON(),
        loc_name: "倉庫",
        loc_id: "warehouse",
      })
      console.log("Warehouse Marker Set:", result.geometry.location.toJSON())
    } catch (error) {
      console.error(`倉庫のジオコーディング失敗 (${address}):`, error)
      setWarehouseMarker(null)
    }
  }

  const handleWarehouseChange = (selectedOption, type) => {
    if (type === "start") {
      setSelectedStartWarehouse(selectedOption)
    } else if (type === "end") {
      setSelectedEndWarehouse(selectedOption)
    }
  }

  const handleDisplayLocations = () => {
    if (selectedUser) {
      dispatch(
        fetchSoldOut({
          warehouseId: selectedStartWarehouse.value.warehouse_id,
          userId: selectedUser.value,
          onlySoldOut: showOnlySoldOut,
          originalPlanId: originalPlanId,
        })
      )
      setDisplayLocations(true)
    }
  }

  const handleCreateRoute = () => {
    const deliveryPlanData = {
      start_date: startDate,
      end_date: endDate,
      user_id: selectedUser.value,
      start_warehouse_id: selectedStartWarehouse.value.warehouse_id,
      end_warehouse_id: selectedEndWarehouse.value.warehouse_id,
      locations: selectedLocations.map((locId, index) => {
        const locName =
          allLocations.find(loc => loc.loc_id === locId)?.loc_name || ""
        return {
          loc_id: locId,
          sequence: index + 1,
          loc_name: locName,
        }
      }),
      status: 1,
      is_adding_locations: isAddingLocations,
      original_plan_id: originalPlanId,
    }

    navigate("/delivery-plan/map", {
      state: {
        startWarehouseId: selectedStartWarehouse.value.warehouse_id,
        endWarehouseId: selectedEndWarehouse.value.warehouse_id,
        selectedLocations,
        startDate,
        endDate,
        selectedUser,
        deliveryPlanData,
        isAddingLocations,
        originalPlanId,
        showOnlySoldOut,
      },
    })
  }

  const createNumberedMarkerIcon = (number, bgColor = "#FF0000", textColor = "#FFFFFF") => {
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
        <circle cx="20" cy="20" r="15" fill="${bgColor}" stroke="#000000" stroke-width="2" />
        <text x="20" y="25" font-size="16" font-weight="bold" text-anchor="middle" fill="${textColor}">${number}</text>
      </svg>
    `
    return `data:image/svg+xml;base64,${btoa(svg)}`
  }

  // ロケーション選択処理 → goods_id が null の場合はモーダルを表示
  const handleLocationClick = (location) => {
    const hasNullGoodsId = location.stocks?.some(
      (stock) => stock.goods_id === null
    )
    if (hasNullGoodsId) {
      setSelectedNullGoodsLocation(location)
      setIsOpen(true)
    } else {
      toggleSelection(location.loc_id)
    }
  }

  const toggleSelection = (locId) => {
    setSelectedLocations((prevSelected) =>
      prevSelected.includes(locId)
        ? prevSelected.filter((id) => id !== locId)
        : [...prevSelected, locId]
    )
  }

  const isButtonDisabled = () => {
    if (
      !selectedStartWarehouse ||
      !selectedEndWarehouse ||
      !selectedUser ||
      !startDate ||
      !endDate
    ) {
      return true
    }

    const start = new Date(startDate)
    const end = new Date(endDate)
    if (end < start) {
      return true
    }
    return false
  }

  const defaultCenter = { lat: 35.6895, lng: 139.6917 }
  const defaultZoom = 10

  useEffect(() => {
    if (location.state?.locations) {
      const completed = location.state.locations
        .filter((loc) => loc.end_refilling_time)
        .map((loc) => loc.id)
      setCompletedLocations(completed)
    }
  }, [location.state])

  useEffect(() => {
    if (isLoaded && mapRef.current && (mapMarkers.some((m) => m) || warehouseMarker)) {
      const bounds = new window.google.maps.LatLngBounds()
      mapMarkers.forEach((marker) => {
        if (marker) {
          bounds.extend(marker.position)
        }
      })
      if (warehouseMarker) {
        bounds.extend(warehouseMarker.position)
      }
      mapRef.current.fitBounds(bounds)
    }
  }, [isLoaded, mapMarkers, warehouseMarker])

  useEffect(() => {
    if (
      (soldOutData.length > 0 || extraLocations.length > 0) &&
      initialSelectedLocations &&
      selectedLocations.length === 0
    ) {
      const filteredLocations = initialSelectedLocations.slice(1, -1)
      setSelectedLocations(filteredLocations)
    }
  }, [soldOutData, extraLocations, initialSelectedLocations, selectedLocations])

  if (loadError) {
    return <div>Error loading Google Maps</div>
  }

  // モーダルの開閉ハンドラ
  const toggleModal = () => setIsOpen(!isOpen)

  // モーダル OK 時の処理（例：商品マスタ登録ページへリダイレクト）
  const handleModalOk = () => {
    // selectedNullGoodsLocation が存在する場合は loc_id を渡す
    if (selectedNullGoodsLocation && selectedNullGoodsLocation.loc_id) {
      window.location.href = `/goods-master?loc_id=${selectedNullGoodsLocation.loc_id}`;
    } else {
      window.location.href = "/goods-master";
    }
  }

  // モーダル キャンセル時は対象ロケーションを選択した状態にする
  const handleModalCancel = () => {
    if (selectedNullGoodsLocation) {
      toggleSelection(selectedNullGoodsLocation.loc_id)
    }
    setIsOpen(false)
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="ホーム" breadcrumbItem="配送計画" />

        <ErrorMessage />
        {hasUnregisteredGoods && (
          <Alert color="danger" timeout={5000}>
            商品マスタに登録されていない商品があります
          </Alert>
        )}

        <LoadingOverlay isLoading={isLoading1 || isLoading2 || isLoading3} />
        <Row>
          <Col xs={12}>
            <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
              <CardBody>
                <h2>補充計画の作成</h2>
                <FormGroup>
                  <Label htmlFor="user">担当者：</Label>
                  <Select
                    id="user"
                    components={makeAnimated()}
                    options={userOptions}
                    onChange={setSelectedUser}
                    value={selectedUser}
                    placeholder="担当者を選択..."
                    isDisabled={isEditMode}
                  />
                </FormGroup>

                <Label htmlFor="user">稼働：</Label>
                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <Input
                        type="datetime-local"
                        name="startDate"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                        min={new Date().toISOString().slice(0, 16)}
                        disabled={isEditMode}
                        style={isEditMode ? { backgroundColor: "#e9ecef" } : {}}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        type="datetime-local"
                        name="endDate"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                        min={startDate}
                        disabled={isEditMode}
                        style={isEditMode ? { backgroundColor: "#e9ecef" } : {}}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <Select
                        id="warehouseStart"
                        components={makeAnimated()}
                        options={warehouseOptions}
                        onChange={(selectedOption) =>
                          handleWarehouseChange(selectedOption, "start")
                        }
                        value={selectedStartWarehouse}
                        placeholder="開始倉庫を選択..."
                        isDisabled={isEditMode}
                      />
                    </Col>
                    <Col xs={6}>
                      <Select
                        id="warehouseEnd"
                        components={makeAnimated()}
                        options={warehouseOptions}
                        onChange={(selectedOption) =>
                          handleWarehouseChange(selectedOption, "end")
                        }
                        value={selectedEndWarehouse}
                        placeholder="終了倉庫を選択..."
                        isDisabled={isEditMode}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup check style={{ marginBottom: "1rem" }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={showOnlySoldOut}
                      onChange={(e) => setShowOnlySoldOut(e.target.checked)}
                      disabled={isEditMode}
                    />{" "}
                    在庫切れが発生しているロケのみ表示
                  </Label>
                </FormGroup>

                <Button
                  type="button"
                  color="primary"
                  block
                  onClick={handleDisplayLocations}
                  disabled={isButtonDisabled() || isEditMode}
                >
                  巡回ロケ候補を表示
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {displayLocations && (
          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <h3 className="mt-4">
                    巡回ロケ候補{" "}
                    <span>（倉庫名: {selectedStartWarehouse?.label || "未設定"}）</span>
                  </h3>
                  {isLoaded && !loadError && (
                    <div style={{ height: "400px", width: "100%", marginBottom: "20px" }}>
                      <GoogleMap
                        mapContainerStyle={{ height: "100%", width: "100%" }}
                        center={
                          mapMarkers.some((m) => m) || warehouseMarker
                            ? undefined
                            : defaultCenter
                        }
                        zoom={
                          mapMarkers.some((m) => m) || warehouseMarker
                            ? undefined
                            : defaultZoom
                        }
                        onLoad={(map) => {
                          mapRef.current = map
                          if (mapMarkers.some((m) => m) || warehouseMarker) {
                            const bounds = new window.google.maps.LatLngBounds()
                            mapMarkers.forEach((marker) => {
                              if (marker) {
                                bounds.extend(marker.position)
                              }
                            })
                            if (warehouseMarker) {
                              bounds.extend(warehouseMarker.position)
                            }
                            map.fitBounds(bounds)
                          }
                        }}
                      >
                        {warehouseMarker && (
                          <Marker
                            key="warehouse"
                            position={warehouseMarker.position}
                            title={warehouseMarker.loc_name}
                            label="W"
                            icon={{
                              url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                              scaledSize: new window.google.maps.Size(32, 32),
                            }}
                          />
                        )}

                        {/* MarkerClusterer の zoomOnClick:false により、独自にクリック処理を実装 */}
                        <MarkerClusterer
                          options={{ zoomOnClick: false }}
                          onLoad={(clusterer) => {
                            // クラスタクリック時のリスナーを追加
                            clusterer.addListener("clusterclick", (cluster) => {
                              const markers = cluster.getMarkers()
                              if (!markers.length) return
                              const firstPos = markers[0].getPosition()
                              // google.maps.geometry ライブラリを利用（libraries設定が必要）
                              const allSame = markers.every((m) => {
                                return (
                                  window.google.maps.geometry.spherical.computeDistanceBetween(
                                    m.getPosition(),
                                    firstPos
                                  ) < 1 // 1メートル未満なら同一座標とみなす
                                )
                              })

                              if (allSame) {
                                // 同一住所の場合は InfoWindow を表示して個別選択可能にする
                                setClusterInfo({
                                  markers,
                                  position: firstPos.toJSON(),
                                })
                              } else {
                                // 異なる場合は通常のズーム処理（cluster の境界に合わせる）
                                const map = cluster.getMap()
                                map.fitBounds(cluster.getBounds())
                              }
                            })
                          }}
                        >
                          {(clusterer) =>
                            mapMarkers.map((marker) => {
                              if (!marker) return null
                              const { loc_id, allLocationsIndex } = marker
                              const isSelected = selectedLocations.includes(loc_id)
                              const isCompleted = completedLocations.includes(loc_id)
                              const number = allLocationsIndex + 1
                              return (
                                <React.Fragment key={loc_id}>
                                  <Marker
                                    clusterer={clusterer}
                                    position={marker.position}
                                    title={marker.loc_name}
                                    onClick={() => {
                                      const locationData = allLocations.find(
                                        (loc) => loc.loc_id === loc_id
                                      );
                                      if (locationData) {
                                        handleLocationClick(locationData);
                                      }
                                    }}
                                    icon={{
                                      url: createNumberedMarkerIcon(
                                        number,
                                        isSelected ? "#0000FF" : "#FF0000",
                                        "#FFFFFF"
                                      ),
                                      scaledSize: new window.google.maps.Size(40, 40),
                                    }}
                                    onLoad={(m) => {
                                      m.set("loc_id", loc_id)
                                      m.set("loc_name", marker.loc_name)
                                    }}
                                  />
                                  {isCompleted && (
                                    <Marker
                                      position={{
                                        lat: marker.position.lat,
                                        lng: marker.position.lng + 0.0001,
                                      }}
                                      icon={{
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        fillColor: "#4CAF50",
                                        fillOpacity: 1,
                                        strokeColor: "#FFFFFF",
                                        strokeWeight: 2,
                                        scale: 10,
                                        labelOrigin: new window.google.maps.Point(0, 0),
                                      }}
                                      label={{
                                        text: "済",
                                        color: "#FFFFFF",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              )
                            })
                          }
                        </MarkerClusterer>

                        {/* InfoWindow 表示：クラスタ内の個別ロケーション一覧 */}
                        {clusterInfo && (
                          <InfoWindow
                            position={clusterInfo.position}
                            onCloseClick={() => setClusterInfo(null)}
                          >
                            <div>
                            {clusterInfo.markers.map((m, idx) => {
                              const locId = m.get("loc_id");
                              const isMarkerSelected = selectedLocations.includes(locId);
                              // allLocations から対象ロケーションを取得
                              const locationData = allLocations.find((loc) => loc.loc_id === locId);
                              return (
                                <div
                                  key={idx}
                                  style={{
                                    cursor: "pointer",
                                    padding: "3px 0",
                                    backgroundColor: isMarkerSelected ? "#d0f0c0" : "transparent"
                                  }}
                                  onClick={() => {
                                    if (locationData) {
                                      handleLocationClick(locationData);
                                    }
                                  }}
                                >
                                  {m.get("loc_name")} {isMarkerSelected ? "(選択済)" : ""}
                                </div>
                              );
                            })}
                            </div>
                          </InfoWindow>
                        )}
                      </GoogleMap>
                    </div>
                  )}

                  {allLocations.length > 0 ? (
                    <Row>
                      {allLocations.map((location, index) => {
                        const isSelected = selectedLocations.includes(location.loc_id)
                        const isCompleted = completedLocations.includes(location.loc_id)
                        return (
                          <Col
                            key={location.loc_id}
                            md={6}
                            lg={8}
                            className="mx-auto"
                          >
                            <Card
                              className="mb-3"
                              style={{
                                border: "1px solid black",
                                backgroundColor: isSelected ? "#ff69b4" : "",
                              }}
                              onClick={() => handleLocationClick(location)}
                            >
                              <CardBody>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div>No {index + 1}</div>
                                  <div>
                                    <strong>ロケ住所:</strong> {location.loc_address || "住所不明"}
                                  </div>
                                </div>
                                <CardTitle
                                  tag="h5"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: isSelected ? "white" : "black",
                                    padding: "5px",
                                    border: "1px solid black",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleLocationClick(location)
                                  }}
                                >
                                  {location.loc_name}
                                  {isCompleted && (
                                    <span style={{ marginLeft: "10px", color: "green" }}>
                                      済
                                    </span>
                                  )}
                                </CardTitle>

                                {location.fromSoldOut && (
                                  <>
                                    <div
                                      className="stock-header"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span style={{ flex: 4 }}>商品名</span>
                                      <span style={{ flex: 1, textAlign: "right" }}>
                                        在庫数
                                      </span>
                                      <span style={{ flex: 1, textAlign: "right" }}>
                                        不足数
                                      </span>
                                    </div>
                                    {location.stocks.map((stock, idx) => (
                                      <div
                                        key={idx}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "2px",
                                          backgroundColor:
                                            stock.goods_id === null
                                              ? "#f5c6cb"
                                              : "inherit",
                                        }}
                                      >
                                        <span style={{ flex: 4 }}>{stock.goods_name}</span>
                                        <span
                                          style={{
                                            flex: 1,
                                            color:
                                              stock.current_stock === 0
                                                ? "red"
                                                : "black",
                                            textAlign: "right",
                                          }}
                                        >
                                          {stock.current_stock}
                                        </span>
                                        <span style={{ flex: 1, textAlign: "right" }}>
                                          {stock.shortage}
                                        </span>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  ) : (
                    <Card>
                      <CardBody>
                        <div style={{ textAlign: "center", padding: "20px" }}>
                          対象のロケーションがありません
                        </div>
                      </CardBody>
                    </Card>
                  )}

                  {((soldOutData.length > 0) || (extraLocations.length > 0)) && (
                    <Button
                      type="button"
                      color="success"
                      block
                      onClick={handleCreateRoute}
                      disabled={selectedLocations.length === 0}
                    >
                      巡回ルートを作成
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>

      {/* モーダル表示 */}
      <Modal isOpen={isOpen} toggle={handleModalCancel}>
        <ModalHeader toggle={handleModalCancel}>未登録商品</ModalHeader>
        <ModalBody>
          このロケーションには商品マスタに登録されていない商品があります。<br />
          商品マスタへ登録しますか？
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleModalOk}>OK</Button>
          <Button color="secondary" onClick={handleModalCancel}>
            キャンセル
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DeliveryPlanCreate
