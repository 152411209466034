import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getGoodsMaster, registerGoodsMaster } from "../../store/goods-master/actions";
import ErrorMessage from "../Common/ErrorMessage";
import LoadingOverlay from "../Common/LoadingOverlay";
import CommonComponent from "../CommonComponent";

const GoodsMaster = () => {
  const dispatch = useDispatch();
  const {
    goodsMasterData,
    loading,
    error,
    registerLoading,
    registerError,
    registerSuccess,
  } = useSelector((state) => state.goodsMasterReducer);

  const [goodsCategory, setGoodsCategory] = useState(null);
  const [locId, setLoc] = useState("");
  const [locGroup, setLocGroup] = useState(null);
  // モーダル表示用のstate
  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    dispatch(getGoodsMaster(goodsCategory, locId));
  }, [dispatch, goodsCategory, locId]);

  /**
   * 登録ボタン押下時のハンドラ
   * 商品名(goods_name)または商品番号(goods_no)がない場合はモーダルを表示し、
   * 正常な場合は registerGoodsMaster をディスパッチして登録処理を実行する
   */
  const handleRegister = (item) => {
    if (!item.goods_name || !item.goods_no) {
      setWarningModal(true);
      return;
    }

    // itemに選択されたlocation_idを追加
    const itemWithLocation = {
      ...item,
      location_id: locId  // CommonComponentで選択されたlocIdを追加
    };

    // 登録処理を実行
    dispatch(registerGoodsMaster(itemWithLocation));
  };

  // 登録成功時に成功モーダルを表示
  useEffect(() => {
    if (registerSuccess) {
      setSuccessModal(true);
      // 成功フラグをリセットするために必要ならアクションをディスパッチ
      // ここでは単純に成功モーダルを閉じた後の処理を追加します
    }
  }, [registerSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Home"
            title="商品管理"
            breadcrumbItem="商品マスタ"
          />
          <ErrorMessage error={error || registerError} />
          <LoadingOverlay isLoading={loading || registerLoading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4>商品マスタ管理</h4>

                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={true}
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    past_loc={false}
                  />

                  {goodsMasterData && goodsMasterData.results && (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>商品名</th>
                            <th>商品番号</th>
                            <th>価格</th>
                            <th>商品マスタID</th>
                            <th>ステータス</th>
                          </tr>
                        </thead>
                        <tbody>
                          {goodsMasterData.results.map((item, index) => (
                            <tr key={index}>
                              <td>{item.goods_name}</td>
                              <td>{item.goods_no}</td>
                              <td>{item.price?.toLocaleString()}円</td>
                              <td>
                                {item.goods_id ? (
                                  <span className="text-success">
                                    登録済み（ID: {item.goods_id}）
                                  </span>
                                ) : (
                                  <span className="text-danger">未登録</span>
                                )}
                              </td>
                              <td>
                                {item.goods_id === null ? (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => handleRegister(item)}
                                    disabled={registerLoading}
                                  >
                                    登録
                                  </Button>
                                ) : item.is_active === 1 ? (
                                  <span className="badge bg-success">有効</span>
                                ) : (
                                  <span className="badge bg-danger">無効</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3">
                        <p>総件数: {goodsMasterData.results.length}件</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* 警告モーダル */}
        <Modal isOpen={warningModal} toggle={() => setWarningModal(!warningModal)}>
          <ModalHeader toggle={() => setWarningModal(!warningModal)}>
            警告
          </ModalHeader>
          <ModalBody>
            クラウド上で商品データを正しく入力してください
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setWarningModal(false)}>
              閉じる
            </Button>
          </ModalFooter>
        </Modal>

        {/* 登録成功モーダル */}
        <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)}>
          <ModalHeader toggle={() => setSuccessModal(!successModal)}>
            成功
          </ModalHeader>
          <ModalBody>
            商品の登録が正常に完了しました。
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setSuccessModal(false)}>
              閉じる
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default GoodsMaster;
