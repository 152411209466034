// src/components/CustomGoogleMap.js

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Polyline,
  Marker,
  MarkerClusterer,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { decode } from "@googlemaps/polyline-codec";
import { googleMapsConfig } from "./googleMapsConfig"; // パスを調整
import "./CustomGoogleMap.css"; // 必要に応じてスタイルを追加
import { getLabel } from "./labelUtils";

const CustomGoogleMap = ({
  decodedRoute = [],
  waypointsInfo = [],
  waypointsNames = [],
  completedLocations = [],
  mapId = "b163a3409a584201",
}) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [clusterMarkers, setClusterMarkers] = useState({
    position: null,
    labels: [],
  });
  const mapRef = useRef(null);

  // Google Maps APIの読み込み設定
  const { isLoaded, loadError } = useJsApiLoader({
    ...googleMapsConfig, // 必要に応じてパスを調整
  });

  if (loadError) {
    return <div>Google Maps APIのロード中にエラーが発生しました。</div>;
  }

  if (!isLoaded) {
    return <div>Google Maps APIをロード中...</div>;
  }

  // クラスタクリック時の処理
  const handleClusterClick = (cluster) => {
    const markers = cluster.getMarkers();

    // クラスタ内の各マーカーが持っている title (＝インデックス文字列) から index を取得し、表示用ラベルを組み立てる
    const clusterInfo = markers.map((marker) => {
      const idxString = marker.getTitle();
      const index = parseInt(idxString, 10);

      if (Number.isNaN(index)) {
        return "表示名不明";
      }

      const label = getLabel(index, waypointsInfo.length);
      const locationName =
        waypointsNames[index]?.name.split(":")[1] ||
        waypointsNames[index]?.name ||
        "表示名不明";

      return `${label}: ${locationName}`;
    });

    setClusterMarkers({
      position: cluster.getCenter().toJSON(),
      labels: clusterInfo,
    });
  };

  // 地図がロードされたときに境界を設定
  const onMapLoad = (map) => {
    mapRef.current = map;
    if (decodedRoute.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      decodedRoute.forEach(([lat, lng]) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{ height: "400px", width: "100%" }}
      onLoad={onMapLoad}
      options={{
        mapId: mapId,
        // 必要に応じて他のオプションを追加
      }}
    >
      {/* ポリラインの描画 */}
      {decodedRoute.length > 0 && (
        <Polyline
          path={decodedRoute.map(([lat, lng]) => ({ lat, lng }))}
          options={{ strokeColor: "#FF0000" }}
        />
      )}

      {/* MarkerClustererを使用してマーカーをクラスタリング */}
      <MarkerClusterer
        options={{
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          zoomOnClick: false, // クラスタークリック時のズームを無効化
        }}
        onLoad={(clusterer) => {
          window.google.maps.event.addListener(
            clusterer,
            "clusterclick",
            handleClusterClick
          );
        }}
      >
        {(clusterer) =>
          waypointsInfo.map((point, index) => (
            <React.Fragment key={index}>
              <Marker
                position={point.location}
                label={getLabel(index, waypointsInfo.length)} // マーカー上のラベル（S, E, 数字など）
                title={`${index}`} // ← インデックスを title に持たせ、一意の情報とする
                clusterer={clusterer}
                onClick={() => setSelectedMarker(index)}
              />

              {completedLocations.includes(index) && (
                <Marker
                  position={{
                    lat: point.location.lat + 0.0001, // 緯度を少しオフセット
                    lng: point.location.lng + 0.0001, // 経度を少しオフセット
                  }}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: "#4CAF50",
                    fillOpacity: 1,
                    strokeColor: "#FFFFFF",
                    strokeWeight: 2,
                    scale: 10,
                    labelOrigin: new window.google.maps.Point(0, 0),
                  }}
                  label={{
                    text: "済",
                    color: "#FFFFFF",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                  title={`completed-${index}`} // こちらも必要に応じてtitleを付ける
                  clusterer={clusterer}
                />
              )}
            </React.Fragment>
          ))
        }
      </MarkerClusterer>

      {/* 選択されたマーカーのInfoWindow */}
      {selectedMarker !== null &&
        waypointsInfo[selectedMarker] &&
        waypointsNames[selectedMarker] && (
          <InfoWindow
            position={waypointsInfo[selectedMarker].location}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="info-window">
              <h5>
                地点 {getLabel(selectedMarker, waypointsInfo.length)}
              </h5>
              <p>
                {waypointsNames[selectedMarker].name.split(":")[1] ||
                  waypointsNames[selectedMarker].name}
                ：
                {waypointsNames[selectedMarker].address
                  .replace(/〒\d{3}-\d{4}/, "")
                  .replace(/〒\d{7}/, "")
                  .replace("日本、", "")}
              </p>
            </div>
          </InfoWindow>
      )}

      {/* クラスター内のマーカー情報を表示するInfoWindow */}
      {clusterMarkers.position && (
        <InfoWindow
          position={clusterMarkers.position}
          onCloseClick={() => setClusterMarkers({ position: null, labels: [] })}
        >
          <div className="cluster-info-window">
            <ul style={{ paddingLeft: "20px", margin: 0 }}>
              {clusterMarkers.labels.map((label, idx) => (
                <li key={idx}>
                  <strong>{label}</strong>
                </li>
              ))}
            </ul>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

CustomGoogleMap.propTypes = {
  decodedRoute: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  waypointsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    })
  ),
  waypointsNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    })
  ),
  completedLocations: PropTypes.arrayOf(PropTypes.number),
  mapId: PropTypes.string,
};

export default CustomGoogleMap;
