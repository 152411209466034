import { call, put, takeEvery } from "redux-saga/effects"
import {
  DELIVERY_PLAN_REQUEST,
  FETCH_ITEMS_FOR_LOADING_REQUEST,
  FETCH_DELIVERY_PLANS_REQUEST,
  UPDATE_DELIVERY_PLAN_REQUEST,
  FETCH_DELIVERY_PLAN_DETAIL_REQUEST,
  // 削除に関するアクションタイプを追加
  DELETE_DELIVERY_PLAN_REQUEST,
  FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST,
} from "./actionTypes"
import {
  createDeliveryPlanSuccess,
  createDeliveryPlanError,
  fetchItemsForLoadingSuccess,
  fetchItemsForLoadingError,
  fetchDeliveryPlansSuccess,
  fetchDeliveryPlansError,
  updateDeliveryPlanSuccess,
  updateDeliveryPlanError,
  fetchDeliveryPlanDetailSuccess,
  fetchDeliveryPlanDetailError,
  deleteDeliveryPlanSuccess,
  deleteDeliveryPlanError,
  fetchIncompleteDeliveryPlansCountSuccess,
  fetchIncompleteDeliveryPlansCountError,
} from "./actions"
import {
  postDeliveryPlan,
  getDeliveryItems,
  getDeliveryPlans,
  updateDeliveryPlan,
  getDeliveryPlanDetail,
  deleteDeliveryPlan,
  getPendingDeliveryPlansCount,
} from "../../helpers/backend_helper"

function* createDeliveryPlanSaga(action) {
  try {
    const response = yield call(postDeliveryPlan, action.payload)
    yield put(createDeliveryPlanSuccess(response.data))
  } catch (error) {
    yield put(createDeliveryPlanError(error.message))
  }
}

function* fetchItemsForLoadingSaga(action) {
  try {
    const response = yield call(getDeliveryItems, action.payload)
    yield put(fetchItemsForLoadingSuccess(response.data))
  } catch (error) {
    yield put(fetchItemsForLoadingError(error.message))
  }
}

function* fetchDeliveryPlansSaga(action) {
  try {
    const response = yield call(getDeliveryPlans, action.payload)
    yield put(fetchDeliveryPlansSuccess(response.plans))
  } catch (error) {
    yield put(fetchDeliveryPlansError(error.message))
  }
}

function* updateDeliveryPlanSaga(action) {
  try {
    const response = yield call(
      updateDeliveryPlan,
      action.payload.delivery_plan
    )
    yield put(updateDeliveryPlanSuccess(response.data))
  } catch (error) {
    yield put(updateDeliveryPlanError(error.message))
  }
}

function* fetchDeliveryPlanDetailSaga(action) {
  try {
    const response = yield call(getDeliveryPlanDetail, action.payload)
    yield put(fetchDeliveryPlanDetailSuccess(response))
  } catch (error) {
    yield put(fetchDeliveryPlanDetailError(error.message))
  }
}

function* deleteDeliveryPlanSaga(action) {
  try {
    const response = yield call(deleteDeliveryPlan, action.payload)
    yield put(deleteDeliveryPlanSuccess(response.data))
  } catch (error) {
    yield put(deleteDeliveryPlanError(error.message))
  }
}

// 未完了配送計画数の取得Saga
function* fetchIncompleteDeliveryPlansCountSaga() {
  try {
    const data = yield call(getPendingDeliveryPlansCount)
    const count = data.count
    yield put(fetchIncompleteDeliveryPlansCountSuccess(count))
  } catch (error) {
    yield put(fetchIncompleteDeliveryPlansCountError(error.message))
  }
}

export default function* deliveryPlanSaga() {
  yield takeEvery(DELIVERY_PLAN_REQUEST, createDeliveryPlanSaga)
  yield takeEvery(FETCH_ITEMS_FOR_LOADING_REQUEST, fetchItemsForLoadingSaga)
  yield takeEvery(FETCH_DELIVERY_PLANS_REQUEST, fetchDeliveryPlansSaga)
  yield takeEvery(UPDATE_DELIVERY_PLAN_REQUEST, updateDeliveryPlanSaga)
  yield takeEvery(
    FETCH_DELIVERY_PLAN_DETAIL_REQUEST,
    fetchDeliveryPlanDetailSaga
  )
  yield takeEvery(DELETE_DELIVERY_PLAN_REQUEST, deleteDeliveryPlanSaga)
  yield takeEvery(
    FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST,
    fetchIncompleteDeliveryPlansCountSaga
  )
}
