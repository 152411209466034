// src/pages/DeliveryPlanMap.js

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSoldOut } from "../../store/soldout/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { decode } from "@googlemaps/polyline-codec";
import makeAnimated from "react-select/animated";
import ErrorMessage from "../Common/ErrorMessage";
import LoadingOverlay from "../Common/LoadingOverlay";
import {
  createDeliveryPlanRequest,
  updateDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions";

// Google Maps のロード状態を管理するフック
import { useJsApiLoader } from "@react-google-maps/api";

// 共通のGoogle Maps設定をインポート（必要に応じてパスを調整）
import { googleMapsConfig } from "./googleMapsConfig";

// 共通のGoogleMapコンポーネントをインポート
import CustomGoogleMap from "./CustomGoogleMap";

import { getLabel } from "./labelUtils"; // ラベル生成関数のインポート

const DeliveryPlanMap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    startWarehouseId,
    endWarehouseId,
    selectedLocations,
    startDate,
    endDate,
    selectedUser,
    deliveryPlanData,
    isAddingLocations,
    originalPlanId,
  } = location.state || {};

  const [decodedRoute, setDecodedRoute] = useState([]);
  const [routeInfo, setRouteInfo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // モーダルの表示状態を管理する状態
  const mapRef = useRef(null);
  const [completedLocations, setCompletedLocations] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // クラスター内のマーカー情報を管理する状態
  const [clusterMarkers, setClusterMarkers] = useState({
    position: null,
    labels: [],
  });

  const isLoading1 = useSelector((state) => state.soldOutReducer.loading);

  // データが存在しない場合に空のオブジェクトを設定
  const route =
    useSelector((state) => state.soldOutReducer.route) || {
      waypoints_info: [],
      waypoints_names: [],
    };
  const warehouses =
    useSelector((state) => state.warehouseReducer.warehouses) || [];
  const users =
    useSelector((state) => state.usersReducer.userData?.data?.results) || [];

  // Google Maps の読み込み状態を管理 (DeliveryPlanShowと同じ方式)
  const { isLoaded, loadError } = useJsApiLoader({
    ...googleMapsConfig, // 必要に応じてパスを調整
  });

  const selectedStartWarehouse = warehouses.find(
    (warehouse) => warehouse.warehouse_id === startWarehouseId
  );
  const selectedEndWarehouse = warehouses.find(
    (warehouse) => warehouse.warehouse_id === endWarehouseId
  );
  const selectedUserDetails = users.find(
    (user) => user.user_id === selectedUser?.value
  );

  useEffect(() => {
    if (route.route) {
      console.log("Original Route Data:", route); // デバッグ用

      const decodedPath = decode(route.route); // デコードして経路を取得

      // waypoints_info と waypoints_names をマージ
      const mergedWaypointsInfo = route.waypoints_info.map((waypoint, index) => ({
        ...waypoint,
        name: route.waypoints_names[index]?.name || `Waypoint ${index + 1}`, // name を追加
      }));

      // 「S」と「E」が同じ位置にあるか確認
      const firstWaypoint = mergedWaypointsInfo[0];
      const lastWaypoint = mergedWaypointsInfo[mergedWaypointsInfo.length - 1];
      let updatedWaypointsInfo = mergedWaypointsInfo;

      if (
        firstWaypoint &&
        lastWaypoint &&
        firstWaypoint.location.lat === lastWaypoint.location.lat &&
        firstWaypoint.location.lng === lastWaypoint.location.lng &&
        mergedWaypointsInfo.length > 1
      ) {
        // 最初と最後が同じ位置にある場合、最後の地点の名前を「E」に修正
        updatedWaypointsInfo = mergedWaypointsInfo.map((waypoint, index) => {
          if (index === mergedWaypointsInfo.length - 1) {
            return { ...waypoint, name: "E" };
          }
          if (index === 0) {
            return { ...waypoint, name: "S" };
          }
          return waypoint;
        });
      } else {
        // 最初の地点を「S」、最後の地点を「E」に設定
        updatedWaypointsInfo = mergedWaypointsInfo.map((waypoint, index) => {
          if (index === 0) {
            return { ...waypoint, name: "S" };
          }
          if (index === mergedWaypointsInfo.length - 1) {
            return { ...waypoint, name: "E" };
          }
          return waypoint;
        });
      }

      const updatedRoute = {
        ...route,
        waypoints_info: updatedWaypointsInfo,
      };

      setDecodedRoute(decodedPath);
      setRouteInfo(updatedRoute);

      // 完了済みのロケーションを特定
      const completed = updatedRoute.waypoints_names
        .map((waypoint, index) => {
          const waypointId = parseInt(waypoint.id, 10); // waypoint.id が数字か確認
          const isCompleted = deliveryPlanData?.locations?.waypoints?.find(
            (wp) => wp.id === waypointId && wp.end_refilling_time
          );
          return isCompleted ? index : null;
        })
        .filter((index) => index !== null);

      setCompletedLocations(completed);
    }
  }, [route, deliveryPlanData]);

  useEffect(() => {
    if (
      startWarehouseId &&
      endWarehouseId &&
      selectedLocations &&
      selectedLocations.length > 0
    ) {
      dispatch(
        fetchSoldOut({
          startWarehouseId: startWarehouseId,
          endWarehouseId: endWarehouseId,
          selectedLocations: selectedLocations,
        })
      );
    }
  }, [startWarehouseId, endWarehouseId, selectedLocations, dispatch]);

  const handleConfirm = () => {
    const updatedDeliveryPlanData = {
      ...deliveryPlanData,
      route: {
        route: route.route,
        total_distance: route.total_distance,
        total_duration: route.total_duration,
        steps: route.steps,
        waypoints_info: route.waypoints_info,
        waypoints_names: route.waypoints_names,
      },
      locations: {
        total_distance: route.total_distance,
        total_duration: route.total_duration,
        waypoints: route.waypoints_names,
      },
    };

    if (originalPlanId) {
      // 編集
      updatedDeliveryPlanData.original_plan_id = originalPlanId;

      // 編集の場合は、配送計画のIDを含める必要があります
      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: updatedDeliveryPlanData,
          plan_id: originalPlanId, // バックエンドでプランを特定するためにIDを渡す
        })
      );
    } else {
      // 新規作成
      dispatch(createDeliveryPlanRequest(updatedDeliveryPlanData));
    }

    alert("配送計画が作成されました");

    navigate("/delivery-plan/create");
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalConfirm = () => {
    toggleModal();
    handleConfirm();
  };

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画確認" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />

          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <h2>ルート情報</h2>
                  <FormGroup>
                    <Label>担当者：</Label>
                    <Select
                      value={{
                        label: selectedUserDetails?.user_name,
                        value: selectedUserDetails?.user_id,
                      }}
                      isDisabled
                      components={makeAnimated()}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>稼働：</Label>
                    <Row>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          value={startDate}
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </Col>
                      <Col xs={6}>
                        <Input
                          type="datetime-local"
                          value={endDate}
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={6}>
                        <Select
                          value={{
                            label: selectedStartWarehouse?.warehouse_name,
                            value: selectedStartWarehouse?.warehouse_id,
                          }}
                          isDisabled
                          components={makeAnimated()}
                        />
                      </Col>
                      <Col xs={6}>
                        <Select
                          value={{
                            label: selectedEndWarehouse?.warehouse_name,
                            value: selectedEndWarehouse?.warehouse_id,
                          }}
                          isDisabled
                          components={makeAnimated()}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <strong>総距離: </strong>
                    {route.total_distance} km
                    <br />
                    <strong>総時間: </strong>
                    {route.total_duration} 分
                  </div>

                  {/* Google Maps のロード状態をチェック */}
                  {loadError && (
                    <div>Google Maps API のロード中にエラーが発生しました。</div>
                  )}
                  {!isLoaded && !loadError && (
                    <div>Google Maps API をロード中...</div>
                  )}

                  {isLoaded && !loadError && decodedRoute.length > 0 && (
                    <CustomGoogleMap
                      decodedRoute={decodedRoute}
                      waypointsInfo={route.waypoints_info}
                      waypointsNames={route.waypoints_names}
                      completedLocations={completedLocations}
                      mapId="b163a3409a584201"
                    />
                  )}

                  <div>
                    {route.waypoints_names &&
                      route.waypoints_names.map((point, index) => {
                        const displayName =
                          point.name.split(":")[1] || point.name; // ":"で分割して、後半部分を取得

                        return (
                          <div className="waypoints-list" key={index}>
                            <Row className="waypoints-item">
                              <Col xs="2" sm="1" md="1" lg="1">
                                <strong className="waypoints-label">
                                  {route.waypoints_info[index]?.name || getLabel(index, route.waypoints_info.length)}:
                                </strong>
                              </Col>
                              <Col xs="10" sm="11" md="11" lg="11">
                                <div className="waypoints-content">
                                  {displayName} (
                                  {point.address
                                    .replace(/〒\d{3}-\d{4}/, "")
                                    .replace(/〒\d{7}/, "")
                                    .replace("日本、", "")}
                                  )
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      color="danger"
                      onClick={() =>
                        navigate("/delivery-plan/create", {
                          state: {
                            startWarehouseId,
                            endWarehouseId,
                            selectedLocations,
                            startDate,
                            endDate,
                            selectedUser,
                            showOnlySoldOut: location.state?.showOnlySoldOut,
                          },
                        })
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      ロケーション再選択
                    </Button>
                    <Button color="success" onClick={toggleModal}>
                      配送計画策定
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>配送計画策定確認</ModalHeader>
          <ModalBody>この内容で配送計画を作成しますか？</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleModalConfirm}>
              登録
            </Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DeliveryPlanMap;
