import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getIpcErrors } from "../../store/ipc-error/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import CommonComponent from "../CommonComponent"

const IpcError = () => {
  const dispatch = useDispatch()
  const { ipcErrorData, loading, error } = useSelector(
    state => state.ipcErrorReducer
  )

  const [locId, setLoc] = useState("")
  const [locGroup, setLocGroup] = useState(null)

  useEffect(() => {
    if (locId) {
      dispatch(getIpcErrors(locId))
    }
  }, [dispatch, locId])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Home"
            title="システム管理"
            breadcrumbItem="障害情報"
          />
          <ErrorMessage error={error} />
          <LoadingOverlay isLoading={loading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4>IPC障害情報</h4>

                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={true}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    past_loc={false}
                  />

                  {ipcErrorData && ipcErrorData.results && (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>ロケーション名</th>
                            <th>IPC番号</th>
                            <th>エラーコード</th>
                            <th>エラーメッセージ</th>
                            <th>発生日時</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ipcErrorData.results.map((item, index) => (
                            <tr key={index}>
                              <td>{item.loc_name}</td>
                              <td>{item.ipc_no}</td>
                              <td>{item.error_code}</td>
                              <td>{item.error_message}</td>
                              <td>{new Date(item.created_at).toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IpcError 