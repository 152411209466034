// helpers/goodsMasterApi.js

import { get, post } from "../api_helper";
import * as url from "../url_helper";

/**
 * 商品マスタの取得
 * @param {string|null} categoryId - カテゴリID
 * @param {string|null} locationId - ロケーションID
 * @returns {Promise<{ data: any }>}
 */
const getGoodsMaster = async (categoryId, locationId) => {
  try {
    const queryParams = new URLSearchParams();

    if (categoryId) {
      queryParams.append("category_id", categoryId);
    }

    if (locationId) {
      queryParams.append("location_id", locationId);
    }

    const queryString = queryParams.toString();
    const response = await get(
      `${url.GET_GOODS_MASTER}${queryString ? `?${queryString}` : ""}`
    );
    return { data: response };
  } catch (error) {
    throw error;
  }
};

/**
 * 商品マスタの登録
 * @param {Object} item - 登録する商品データ
 * @param {string} item.goods_name - 商品名
 * @param {string} item.goods_no - 商品番号
 * @param {number} item.price - 価格
 * @param {string} [item.categoryId] - カテゴリID（必要に応じて）
 * @param {string} [item.locationId] - ロケーションID（必要に応じて）
 * @returns {Promise<{ data: any }>}
 */
const registerGoodsMaster = async (item) => {
  try {
    const response = await post(url.REGISTER_GOODS_MASTER, item);
    return { data: response };
  } catch (error) {
    throw error;
  }
};

export {
  getGoodsMaster,
  registerGoodsMaster,
};
